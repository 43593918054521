import React from "react"

import Layout from "../components/layout"

const TermsConditions = () => (
  <Layout>
    <div className="wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-terms-privacy">
              <h1>الشروط والأحكام</h1>
              <br />
              <h2>
                {" "}
                <strong> البنود والشروط </strong>{" "}
              </h2>

              <p> مرحبًا بك في نشرة! </p>

              <p>
                {" "}
                تحدد هذه الشروط والأحكام القواعد واللوائح الخاصة باستخدام موقع
                نهج الإلكتروني ، الموجود على https://www.nshra.com.{" "}
              </p>

              <p>
                {" "}
                بدخولك إلى هذا الموقع ، نفترض أنك تقبل هذه الشروط والأحكام. لا
                تستمر في استخدام نشرة إذا كنت لا توافق على اتخاذ جميع الشروط
                والأحكام المذكورة في هذه الصفحة.{" "}
              </p>

              <p>
                {" "}
                تنطبق المصطلحات التالية على هذه الشروط والأحكام وبيان الخصوصية
                وإشعار إخلاء المسؤولية وجميع الاتفاقيات: يشير مصطلح "العميل" و
                "أنت" و "الخاص بك" إليك ، والشخص الذي يقوم بتسجيل الدخول إلى هذا
                الموقع ويتوافق مع شروط الشركة و الظروف. تشير "الشركة" و "أنفسنا"
                و "نحن" و "لنا" و "نحن" إلى شركتنا. يشير مصطلح "الطرف" أو
                "الأطراف" أو "نحن" إلى كل من العميل وأنفسنا. تشير جميع الشروط
                إلى العرض والقبول والنظر في الدفع اللازم للاضطلاع بعملية
                مساعدتنا للعميل بالطريقة الأنسب للغرض الصريح المتمثل في تلبية
                احتياجات العميل فيما يتعلق بتوفير خدمات الشركة المعلنة ، وفقًا
                لـ وتخضع للقانون السائد في هولندا. أي استخدام للمصطلحات المذكورة
                أعلاه أو غيرها من الكلمات في صيغة المفرد والجمع و / أو هو / هي
                أو هم ، يتم اعتباره قابلاً للتبادل وبالتالي يشير إلى نفسه.{" "}
              </p>

              <h3>
                {" "}
                <strong> ملفات تعريف الارتباط </strong>{" "}
              </h3>

              <p>
                {" "}
                نحن نستخدم استخدام ملفات تعريف الارتباط. من خلال الوصول إلى
                نشرة، فإنك توافق على استخدام ملفات تعريف الارتباط بالاتفاق مع
                سياسة الخصوصية نهج.{" "}
              </p>

              <p>
                {" "}
                تستخدم معظم مواقع الويب التفاعلية ملفات تعريف الارتباط للسماح
                لنا باسترداد تفاصيل المستخدم لكل زيارة. يستخدم موقعنا الإلكتروني
                ملفات تعريف الارتباط لتمكين وظائف مناطق معينة لتسهيل زيارة
                الأشخاص لموقعنا. قد يستخدم بعض الشركاء التابعين / المعلنين أيضًا
                ملفات تعريف الارتباط.{" "}
              </p>

              <h3>
                {" "}
                <strong> الترخيص </strong>{" "}
              </h3>

              <p>
                {" "}
                ما لم يُنص على خلاف ذلك ، تمتلك نهج و / أو المرخصون التابعون لها
                حقوق الملكية الفكرية لجميع المواد الموجودة على نشرة. جميع حقوق
                الملكية الفكرية محفوظة. يمكنك الوصول إلى هذا من نشرة لاستخدامك
                الشخصي مع مراعاة القيود المنصوص عليها في هذه الشروط والأحكام.{" "}
              </p>

              <p> لا يجب عليك: </p>
              <ul>
                <li> إعادة نشر المواد من نشرة </li>
                <li> بيع أو تأجير أو ترخيص المواد من نشرة </li>
                <li> إعادة إنتاج أو نسخ أو نسخ المواد من نشرة </li>
                <li> إعادة توزيع المحتوى من نشرة </li>
              </ul>

              <p>
                {" "}
                توفر أجزاء من هذا الموقع فرصة للمستخدمين لنشر وتبادل الآراء
                والمعلومات في مناطق معينة من الموقع. لا تقوم نهج بتصفية أو تحرير
                أو نشر أو مراجعة التعليقات قبل وجودها على الموقع. لا تعكس
                التعليقات آراء وآراء نهج ووكلائها و / أو الشركات التابعة لها.
                تعكس التعليقات وجهات نظر وآراء الشخص الذي ينشر وجهات نظره
                وآرائه. إلى الحد الذي تسمح به القوانين المعمول بها ، لن تكون نهج
                مسؤولة عن التعليقات أو عن أي مسؤولية أو أضرار أو نفقات ناتجة و /
                أو تكبدتها نتيجة لأي استخدام و / أو نشر و / أو ظهور التعليقات
                على هذا الموقع.{" "}
              </p>

              <p>
                {" "}
                تحتفظ نهج بالحق في مراقبة جميع التعليقات وإزالة أي تعليقات يمكن
                اعتبارها غير ملائمة أو مسيئة أو تسبب انتهاكًا لهذه الشروط
                والأحكام.{" "}
              </p>

              <p> أنت تضمن وتقر بما يلي: </p>

              <ul>
                <li>
                  {" "}
                  يحق لك نشر التعليقات على موقعنا ولديك جميع التراخيص والموافقات
                  اللازمة للقيام بذلك ؛{" "}
                </li>
                <li>
                  {" "}
                  لا تنتهك التعليقات أي حق من حقوق الملكية الفكرية ، بما في ذلك
                  على سبيل المثال لا الحصر حقوق الطبع والنشر أو براءة الاختراع
                  أو العلامة التجارية لأي طرف ثالث ؛{" "}
                </li>
                <li>
                  {" "}
                  لا تحتوي التعليقات على أي مواد تشهيرية أو تشهيرية أو مسيئة أو
                  غير لائقة أو غير قانونية بأي شكل مما يعد انتهاكًا للخصوصية{" "}
                </li>
                <li>
                  {" "}
                  لن يتم استخدام التعليقات لالتماس أو الترويج لأنشطة تجارية أو
                  مخصصة أو حالية أو نشاط غير قانوني.{" "}
                </li>
              </ul>

              <p>
                {" "}
                أنت تمنح بموجب هذا نهج ترخيصًا غير حصري لاستخدام وإعادة إنتاج
                وتحرير وتفويض الآخرين لاستخدام وإعادة إنتاج وتحرير أي من
                تعليقاتك في أي وجميع الأشكال أو الأشكال أو الوسائط.{" "}
              </p>

              <h3>
                {" "}
                <strong> الارتباط التشعبي إلى المحتوى الخاص بنا </strong>{" "}
              </h3>

              <p>
                {" "}
                قد ترتبط المنظمات التالية بموقعنا على الويب دون موافقة كتابية
                مسبقة:{" "}
              </p>

              <ul>
                <li> الهيئات الحكومية ؛ </li>
                <li> محركات البحث </li>
                <li> المؤسسات الإخبارية </li>
                <li>
                  {" "}
                  يجوز لموزعي الدليل عبر الإنترنت الارتباط بموقعنا على الويب
                  بنفس طريقة الارتباط التشعبي لمواقع الويب الخاصة بشركات أخرى
                  مدرجة ؛ و{" "}
                </li>
                <li>
                  {" "}
                  الشركات المعتمدة على مستوى النظام باستثناء طلب المنظمات غير
                  الربحية ومراكز التسوق الخيرية ومجموعات جمع التبرعات الخيرية
                  التي قد لا ترتبط ارتباطًا تشعبيًا بموقعنا على الويب.{" "}
                </li>
              </ul>

              <p>
                {" "}
                قد ترتبط هذه المنظمات بصفحتنا الرئيسية أو بالمنشورات أو بمعلومات
                أخرى على موقع الويب طالما أن الرابط: (أ) ليس بأي حال من الأحوال
                خادع (ب) لا يعني ضمنيًا رعاية أو تأييد أو موافقة الطرف المرتبط
                ومنتجاته و / أو خدماته ؛ و (ج) يناسب سياق موقع الطرف المرتبط.{" "}
              </p>

              <p>
                {" "}
                يجوز لنا النظر في طلبات الارتباط الأخرى والموافقة عليها من
                الأنواع التالية من المؤسسات:{" "}
              </p>

              <ul>
                <li>
                  {" "}
                  مصادر معلومات المستهلك و / أو النشاط التجاري المعروفة ؛{" "}
                </li>
                <li> مواقع مجتمعات dot.com </li>
                <li> جمعيات أو مجموعات أخرى تمثل الجمعيات الخيرية ؛ </li>
                <li> موزعو الدليل عبر الإنترنت ؛ </li>
                <li> بوابات الإنترنت </li>
                <li> شركات المحاسبة والقانون والاستشارات ؛ و </li>
                <li> المؤسسات التعليمية والجمعيات التجارية. </li>
              </ul>

              <p>
                {" "}
                سنوافق على طلبات الارتباط من هذه المؤسسات إذا قررنا ما يلي: (أ)
                لن يجعلنا الرابط ننظر بشكل غير موات لأنفسنا أو لشركاتنا المعتمدة
                ؛ (ب) ليس لدى المنظمة أي سجلات سلبية معنا ؛ (ج) تعوض الاستفادة
                من رؤية الارتباط التشعبي غياب نهج ؛ و (د) الارتباط في سياق
                معلومات الموارد العامة.{" "}
              </p>

              <p>
                {" "}
                قد ترتبط هذه المنظمات بصفحتنا الرئيسية طالما أن الرابط: (أ) ليس
                مخادعًا بأي شكل من الأشكال ؛ (ب) لا يعني ضمنيًا رعاية أو تأييد
                أو موافقة الطرف المرتبط ومنتجاته أو خدماته ؛ و (ج) يناسب سياق
                موقع الطرف المرتبط.{" "}
              </p>

              <p>
                {" "}
                إذا كنت أحد المنظمات المدرجة في الفقرة 2 أعلاه وتهتم بالارتباط
                بموقعنا على الويب ، فيجب عليك إبلاغنا عن طريق إرسال بريد
                إلكتروني إلى نهج. يرجى تضمين اسمك واسم مؤسستك ومعلومات الاتصال
                بالإضافة إلى عنوان URL لموقعك وقائمة بأي عناوين URL تنوي
                الارتباط بموقعنا على الويب وقائمة عناوين URL الموجودة على موقعنا
                والتي ترغب في الوصول إليها حلقة الوصل. انتظر 2-3 أسابيع للرد.{" "}
              </p>

              <p>
                {" "}
                يجوز للمنظمات المعتمدة الارتباط التشعبي لموقعنا على النحو
                التالي:{" "}
              </p>

              <ul>
                <li> باستخدام اسم شركتنا ؛ أو </li>
                <li> من خلال استخدام محدد موقع الموارد المرتبط بـ ؛ أو </li>
                <li>
                  {" "}
                  من خلال استخدام أي وصف آخر لموقعنا على الويب يتم ربطه بهذا
                  الأمر في سياق وشكل المحتوى على موقع الطرف المرتبط.{" "}
                </li>
              </ul>

              <p>
                {" "}
                لن يُسمح باستخدام شعار نهج أو أي عمل فني آخر لربط غياب اتفاقية
                ترخيص العلامة التجارية.{" "}
              </p>

              <h3>
                {" "}
                <strong>iFrames</strong>{" "}
              </h3>

              <p>
                {" "}
                بدون موافقة مسبقة وإذن كتابي ، لا يجوز لك إنشاء إطارات حول صفحات
                الويب الخاصة بنا والتي تغير بأي شكل من الأشكال العرض التقديمي
                المرئي لموقعنا أو مظهره.{" "}
              </p>

              <h3>
                {" "}
                <strong> مسؤولية المحتوى </strong>{" "}
              </h3>

              <p>
                {" "}
                لن نتحمل المسؤولية عن أي محتوى يظهر على موقع الويب الخاص بك. أنت
                توافق على حمايتنا والدفاع عنا ضد جميع المطالبات التي تنشأ على
                موقع الويب الخاص بك. يجب ألا يظهر أي رابط (روابط) على أي موقع
                ويب يمكن تفسيره على أنه تشهيري أو فاحش أو إجرامي ، أو ينتهك أو
                ينتهك أو يدعو إلى التعدي أو انتهاك آخر لحقوق أي طرف ثالث.{" "}
              </p>

              <h3>
                {" "}
                <strong> خصوصيتك </strong>{" "}
              </h3>

              <p> يرجى قراءة سياسة الخصوصية </p>

              <h3>
                {" "}
                <strong> حجز الحقوق </strong>{" "}
              </h3>

              <p>
                {" "}
                نحتفظ بالحق في مطالبتك بإزالة جميع الروابط أو أي رابط معين
                لموقعنا على الويب. أنت توافق على إزالة جميع الروابط إلى موقعنا
                على الفور عند الطلب. نحتفظ أيضًا بالحق في تعديل هذه الشروط
                والأحكام وسياسة الربط الخاصة بها في أي وقت. من خلال الارتباط
                المستمر بموقعنا على الويب ، فإنك توافق على الالتزام بشروط وأحكام
                الربط هذه واتباعها.{" "}
              </p>

              <h3>
                {" "}
                <strong> إزالة الروابط من موقعنا </strong>{" "}
              </h3>

              <p>
                {" "}
                إذا وجدت أي رابط على موقعنا مسيء لأي سبب من الأسباب ، فأنت حر في
                الاتصال وإبلاغنا في أي لحظة. سننظر في طلبات إزالة الروابط ولكننا
                لسنا ملزمين بذلك أو بالرد عليك مباشرة.{" "}
              </p>

              <p>
                {" "}
                لا نضمن صحة المعلومات الواردة في هذا الموقع ، ولا نضمن اكتمالها
                أو دقتها ؛ كما أننا لا نعد بضمان بقاء موقع الويب متاحًا أو تحديث
                المواد الموجودة على الموقع.{" "}
              </p>

              <h3>
                {" "}
                <strong> إخلاء المسؤولية </strong>{" "}
              </h3>

              <p>
                {" "}
                إلى أقصى حد يسمح به القانون المعمول به ، نستبعد جميع الإقرارات
                والضمانات والشروط المتعلقة بموقعنا على الويب واستخدام هذا
                الموقع. لا شيء في إخلاء المسؤولية هذا:{" "}
              </p>

              <ul>
                <li>
                  {" "}
                  تحديد أو استبعاد مسؤوليتنا أو مسؤوليتك عن الوفاة أو الإصابة
                  الشخصية ؛{" "}
                </li>
                <li>
                  {" "}
                  تحديد أو استبعاد مسؤوليتنا أو مسؤوليتك عن الاحتيال أو التحريف
                  الاحتيالي ؛{" "}
                </li>
                <li>
                  {" "}
                  تحديد أي من التزاماتنا أو التزاماتك بأي طريقة غير مسموح بها
                  بموجب القانون المعمول به ؛ أو{" "}
                </li>
                <li>
                  {" "}
                  استبعاد أي من التزاماتنا أو التزاماتك التي قد لا يتم استبعادها
                  بموجب القانون المعمول به.{" "}
                </li>
              </ul>

              <p>
                {" "}
                قيود وحظر المسؤولية المنصوص عليها في هذا القسم وفي أي مكان آخر
                في إخلاء المسؤولية هذا: (أ) تخضع للفقرة السابقة ؛ و (ب) تحكم
                جميع الالتزامات الناشئة بموجب إخلاء المسؤولية ، بما في ذلك
                الالتزامات الناشئة في العقد ، في حالة المسؤولية التقصيرية وخرق
                الواجب القانوني.{" "}
              </p>

              <p>
                {" "}
                طالما يتم توفير موقع الويب والمعلومات والخدمات على الموقع مجانًا
                لن نكون مسؤولين عن أي خسارة أو ضرر من أي نوع.{" "}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default TermsConditions
